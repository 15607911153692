import React from 'react';
import { ReactComponent as Logo } from './assets/logo.svg';
import { Email } from "react-obfuscate-email";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="cardContent">
          <div className="header">
            <div className="logo">
              <a href="/"><Logo className="logoImg" /></a>
            </div>
          </div>
          <div className="content">
            <div className="title-holder">
              <h1>Get ready for the change.</h1>
              <p>New website coming soon.<br />Please check back to know more. Shoot us an email if you're curious.</p>
            </div>
            <Email email="office@incoralliance.com">
              <div className="cta">Send us an email</div>
            </Email>
          </div>
        </div>
      </div>
    );
  }
}

export default App;